import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { useSpring, animated } from '@react-spring/web';

import styled from 'styled-components';
import { imageDB } from '../utility/imageData';
import { BetweenRow, FlexstartRow, Row } from '../common/Row';
import { UserContext } from '../context/User';
import { ReadCATEGORY } from '../service/CategoryService';
import { useNavigate } from 'react-router-dom';

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { ko, se } from 'date-fns/locale';
import { FaRegCalendarCheck } from "react-icons/fa6";
import MobileCalendarPopup from './MobileCalendarPopup';
import { CreateFreeze } from '../service/FreezeService';
import { sleep } from '../utility/common';

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};
//   transform: 'translate(-50%, -50%)',
const style = {
    position: 'absolute',
    top: '70%',
    left: '50%',
    height:'300px',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '14px 34px',
    zIndex:100,
};
const IconCloseView = styled.div`

`
const MainData = styled.div`
  display :flex;
  flex-direction:row;
  background-color : #fff;
  flex-wrap : wrap;
  margin: 0 auto;
  width:80%;
  padding-right: 30px;
`
// background-color :  ${({check}) => check == 1 ? "#ff4e193b" : "#EDEDED" }; 
const MainDataItem = styled.div`
    padding: 5px 10px;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    border-radius: 5px;
    width: 40%;
    background-color: #fff;
    margin-left: 10px;
    margin-bottom: 10px;
`
const MainDataItemText = styled.span`
  font-size :16px;
  font-weight:500;
  font-family : ${({theme}) =>theme.REGULAR};
  color :  ${({check}) => check == 1 ? "#FF4E19" : "#000" };  

`
const ApplyItem = styled.div`
  display :flex;
  flex-direction : row;
  justify-content : center;
  align-items : center;
  background-color : #fff;
  margin-bottom : 20px;
`
const FilterApplyButton = styled.div`
    background-color :#FF7125;
    padding :0px 24px;
    border-radius :100px;
    height:46px;
    display:flex;
    justify-content:center;
    align-items:center;

`
const FilterApplyButtonText = styled.span`
  color :#fff;
  font-size :18px;
  font-family : ${({theme}) =>theme.REGULAR};
  font-weight:700;
`

const HeaderPopupline = styled.div`

  width:20%;
  background:#E3E3E3;
  height:4px;
`



const Inputstyle={
  background: '#FFF',
  borderRadius:'5px',
  fontSize: '16px',
  padding: '0px 16px 0px 16px',
  height : '40px',
  border : "1px solid #EDEDED",

}

const Label = styled.div`
  font-size: 16px;
  color: rgb(19, 19, 19);
  width: 40%;
  margin: 10px auto;
`








export default function MobileGameResult({callback, resulttype, trynumber, minutes, remainingSeconds}) {
  console.log("🚀 ~ MobileGameResult ~ trynumber:", trynumber)
  const [open, setOpen] = React.useState(true);
  const [refresh, setRefresh] = React.useState(-1);
  const [categoryitems, setCategoryitems] = React.useState([]);
  const navigate = useNavigate();
  const { dispatch, user } = React.useContext(UserContext);

 


  const handleClose = () =>{
    setOpen(false);
    callback('');
  } 



  React.useEffect(()=>{


  },[refresh])

  React.useEffect(()=>{
    async function FetchData(){

    }
    FetchData();
  }, [])

  const _handleadd = async()=>{

    callback();
    setOpen(false);
    setRefresh((refresh)=> refresh +1);
  }







  return (
    <div>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Row>
              <HeaderPopupline/>
            </Row>


            <BetweenRow style={{width:"75%", margin: "20px auto", }}>
              <div style={{fontSize:"18px", fontWeight:900, color:"#131313", fontFamily:'Pretendard-SemiBold'}}>게임 결과</div>
              <IconCloseView onClick={handleClose} >
              <img src={imageDB.close} style={{width:"22px", height:"22px"}}/>
              </IconCloseView>
            </BetweenRow>

            {
              resulttype =='success' && <>
              <Row style={{width:"75%", margin:"15px auto"}}>
              <Label>게임 횟수</Label>
              <div>{trynumber}건</div>
              </Row>
              <Row style={{width:"75%", margin:"15px auto"}}>
              <Label>게임 진행 시간</Label>
              <div>{minutes} : {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}</div>
              </Row>
              <ApplyItem>
                <div style={{dispaly:"flex", alignItems:"center", justifyContent:"center", width:"50%", marginTop:20}}>   
                    <FilterApplyButton onClick ={_handleadd}><FilterApplyButtonText>결과 업로드</FilterApplyButtonText></FilterApplyButton>
                </div>
              </ApplyItem>
              </>
            }
            {
              resulttype =='fail' && <>
              <Row style={{width:"75%", margin:"100px auto", fontSize:25}}>
              <div>클릭 횟수 60회를 추가 하였습니다. 다시 진행해주세요</div>
              </Row>     
              </>
            }
 
            {
              resulttype =='stop' && <>
              <Row style={{width:"75%", margin:"100px auto", fontSize:25}}>
              <div>진행하던 게임을 중지되었습니다</div>
              </Row>     
              </>
            }


          </Box>
        </Fade>
      </Modal>
    </div>
  );
}